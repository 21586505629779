import slider from "../../resources/js/modules/sliders";
const locationSliders = document.querySelectorAll(".hop-locations-slider__slider .swiper");

if (locationSliders) {
    slider(locationSliders, {
        slidesPerView: 1,
        spaceBetween: 30,

        breakpoints: {
            767: {
                slidesPerView: 1.5,
                spaceBetween: 30,
            },

            993: {
                slidesPerView: 2,
                spaceBetween: 30,
            },

            1024: {
                slidesPerView: 2.3,
                spaceBetween: 30,
            },
        },

        navigation: {
            nextEl: '.location-btn-next',
            prevEl: '.location-btn-prev',
        },
    });
}
